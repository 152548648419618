import $api from "../http";
import { AxiosResponse } from "axios";

export type ShowcaseDownload = {
  showcasedownloadid: string;
  recorddatecreate: Date;
  recordusernamecreate: string;
  recorddateupdate: Date;
  recordusernameupdate: string;
  productversionid: string;
  productid: string;
  architecture: number;
  productname: string;
  versionmajor: number;
  versionminor: number;
  versionrelease: number;
  versionbuild: number;
  activity: boolean;
  urldownload: string;
  urlpage: string;
  shopnamesys: string;
  recordorder: number;
  imageid: string;
  description: any;
};

export type ShowcaseDownloadList = {
  totalcount: number;
  items: ShowcaseDownload[];
};

export default class ShowcaseDownloadService {
  static async getAll(): Promise<AxiosResponse<ShowcaseDownloadList>> {
    return $api.get<ShowcaseDownloadList>("/showcase-download");
  }
}
