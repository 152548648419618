import { createAsyncThunk } from "@reduxjs/toolkit";
import { CartItem } from "./types";
import CartService from "../../services/CartService";

export const cartFetch = createAsyncThunk<CartItem[]>("cart/cartFetch", async () => {
  const res = await CartService.getAll();
  return res.data;
});

export const cartInsert = createAsyncThunk<CartItem, string>("cart/cartInsert", async (params) => {
  const res = await CartService.insert(params);
  return res.data;
});

export const cartIncrement = createAsyncThunk<string, string>("cart/cartIncrement", async (params) => {
  await CartService.increment(params);
  return params;
});

export const cartDecrement = createAsyncThunk<string, string>("cart/cartDecrement", async (params) => {
  await CartService.decrement(params);
  return params;
});

export const cartDelete = createAsyncThunk<string, string>("cart/cartDelelte", async (params) => {
  await CartService.delete(params);
  return params;
});

export const cartClear = createAsyncThunk("cart/cartClear", async () => {
  const res = await CartService.clear();
  return res.data;
});
