import React from "react";
import UserProfileService from "../../services/UserProfileService";

const ChangePhoneItem: React.FC = () => {
  const [phone, setPhone] = React.useState<string>("");

  React.useEffect(() => {
    UserProfileService.peopleGet()
      .then((res) => {
        setPhone(res.data.phone);
      })
      .catch((e: any) => {
        if (e.response) {
          console.log(e.response.data);
        } else {
          console.log("Error", e.message);
        }
      });
  }, []);

  const onClickApply = () => {
    UserProfileService.peopleUpdatePhone(phone).catch((e: any) => {
      if (e.response) {
        alert(e.response.data);
      } else {
        alert(e.message);
      }
    });
  };

  return (
    <div>
      <div className="mb-3">
        <h5 className="mb-4">Изменить номер телефона</h5>

        <label className="form-label mb-1">Номер телефона</label>
        <input type="value" className="form-control mb-3" value={phone} onChange={(e) => setPhone(e.target.value)} />
      </div>

      <button type="submit" className="btn style-btn-action" onClick={onClickApply}>
        Применить
      </button>
    </div>
  );
};

export default ChangePhoneItem;
