import { createSlice } from "@reduxjs/toolkit";
import { CartItem, CartStatus, CartSliceState } from "./types";
import { cartFetch, cartInsert, cartIncrement, cartDecrement, cartDelete, cartClear } from "./asyncActions";

const initialState: CartSliceState = {
  totalPrice: 0,
  items: [],
  status: CartStatus.LOADING, // loading | success | error
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(cartFetch.pending, (state, action) => {
      state.status = CartStatus.LOADING;
      state.items = [];
      state.totalPrice = 0;
    });

    builder.addCase(cartFetch.fulfilled, (state, action) => {
      state.items = action.payload;
      state.status = CartStatus.SUCCESS;
      state.totalPrice = state.items.reduce((sum: number, item: CartItem) => sum + item.cnt * item.price, 0);
    });

    builder.addCase(cartFetch.rejected, (state, action) => {
      state.status = CartStatus.ERROR;
      state.items = [];
    });

    builder.addCase(cartInsert.fulfilled, (state, action) => {
      console.log(action.payload);

      const cartItem = state.items.find((item) => item.nomenclatureid === action.payload.nomenclatureid);
      if (cartItem) cartItem.cnt++;
      else state.items.push(action.payload);
      state.totalPrice = state.items.reduce((sum: number, item: CartItem) => sum + item.cnt * item.price, 0);
      state.status = CartStatus.SUCCESS;
    });

    builder.addCase(cartIncrement.fulfilled, (state, action) => {
      console.log(action.payload);

      const cartItem = state.items.find((item) => item.nomenclatureid === action.payload);
      if (cartItem) cartItem.cnt++;

      state.totalPrice = state.items.reduce((sum: number, item: CartItem) => sum + item.cnt * item.price, 0);
      state.status = CartStatus.SUCCESS;
    });

    builder.addCase(cartDecrement.fulfilled, (state, action) => {
      const cartItem = state.items.find((item) => item.nomenclatureid === action.payload);
      if (cartItem) {
        cartItem.cnt--;
        if (cartItem.cnt === 0) {
          state.items = state.items.filter((item) => item.nomenclatureid !== cartItem.nomenclatureid);
        }
      }
      state.totalPrice = state.items.reduce((sum: number, item: CartItem) => sum + item.cnt * item.price, 0);
      state.status = CartStatus.SUCCESS;
    });

    builder.addCase(cartDelete.fulfilled, (state, action) => {
      state.items = state.items.filter((item) => item.nomenclatureid !== action.payload);
      state.totalPrice = state.items.reduce((sum: number, item: CartItem) => sum + item.cnt * item.price, 0);
      state.status = CartStatus.SUCCESS;
    });

    builder.addCase(cartClear.fulfilled, (state, action) => {
      state.items = [];
      state.totalPrice = 0;
      state.status = CartStatus.SUCCESS;
    });
  },
});

export default cartSlice.reducer;
