import $api from "../http";
import { AxiosResponse } from "axios";

export default class UserProfileService {
  static peopleGet(): Promise<AxiosResponse<any>> {
    return $api.get<any>("/userprofile");
  }

  static peopleUpdateFio({ surname, name, middlename }: any): Promise<AxiosResponse<any>> {
    return $api.put<any>("/userprofile", { surname, name, middlename });
  }
  static peopleUpdatePhone(phone: string): Promise<AxiosResponse<any>> {
    return $api.put<any>("/userprofile/phone", { phone });
  }

  static emailUpdate({ email }: any): Promise<AxiosResponse<any>> {
    return $api.put<any>("/userprofile/email", { email });
  }

  static passwdUpdate({ password_old, password_new }: any): Promise<AxiosResponse<any>> {
    return $api.put<any>("/userprofile/password", { password_old, password_new });
  }

  static delete(): Promise<AxiosResponse<any>> {
    return $api.delete<any>("/userprofile");
  }
}
