import $api from "../http";
import { AxiosResponse } from "axios";

export type Organization = {
  organizationid: string;
  name: string;
  inn: string;
  namefull: string;
  kpp: string;
  pookpo: string;
  code: string;
};

export type OrganizationList = {
  items: Organization[];
  total_count: number;
};

export default class ShopService {
  static organizationGet(): Promise<AxiosResponse<OrganizationList>> {
    return $api.get<OrganizationList>(`/organization`);
  }
  static organizationInsert(organization: Organization): Promise<AxiosResponse<Organization>> {
    return $api.post<Organization>(`/organization`, organization);
  }
  static organizationUpdate(organization: Organization): Promise<AxiosResponse<Organization>> {
    return $api.put<Organization>(`/organization`, organization);
  }
  static organizationDelete(organizationid: string): Promise<AxiosResponse<Organization>> {
    return $api.delete<Organization>(`/organization/${organizationid}`);
  }
}
