import $api from "../http";
import { AxiosResponse } from "axios";
import { CartItem } from "../redux/cart/types";

export default class CartService {
  static async getAll(): Promise<AxiosResponse<CartItem[]>> {
    return $api.get<CartItem[]>("/cart");
  }

  static async insert(nomenclatureid: String): Promise<AxiosResponse<CartItem>> {
    return $api.post<CartItem>(`/cart/${nomenclatureid}`);
  }

  static async delete(nomenclatureid: String): Promise<AxiosResponse> {
    return $api.delete(`/cart/${nomenclatureid}`);
  }

  static async increment(nomenclatureid: string): Promise<AxiosResponse> {
    return $api.put(`cart/${nomenclatureid}/increment`);
  }

  static async decrement(nomenclatureid: string): Promise<AxiosResponse> {
    return $api.put(`cart/${nomenclatureid}/decrement`);
  }

  static async clear(): Promise<AxiosResponse<string>> {
    return $api.delete<string>(`/cart/clear`);
  }
}
