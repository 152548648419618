const config = {
  // API_URL: "http://localhost:62222/api",
  // API_URL: 'http://localhost:8000/api'
     API_URL: "https://neg24.ru/api",

  // API_WEBSOCKET: "http://localhost:8000",
     API_WEBSOCKET: "http://neg24.ru",
     SHOP: "neg24",
};

export default config;