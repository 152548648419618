import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import downloads from "../../../assets/img/svg/downloads.svg";
import config from "../../../config";
import { InvoiceService } from "../../../services/InvoiceService";
import ShopService, { Organization } from "../../../services/ShopService";
import OrganizationControl, { OrganizationControlState } from "../../OrganizationPage/OrganizationControl";

const OrganizationOrder: React.FC = () => {
  enum Status {
    list,
    add,
  }
  enum BillStatus {
    notFormed,
    pending,
    ready,
  }
  const [billStatus, setBillStatus] = useState<BillStatus>(BillStatus.notFormed);
  const [invoiceId, setInvoiceId] = useState<string>("");
  const [organizationid, setOrganizationid] = React.useState("");
  const [items, setItems] = React.useState([] as Organization[]);
  const [status, setStatus] = React.useState<Status>(Status.list);

  useEffect(() => {
    ShopService.organizationGet()
      .then((res) => {
        setItems(res.data.items);
      })
      .catch((e: any) => {
        if (e.response) {
          console.log(e.response.data);
        } else {
          console.log("Error", e.message);
        }
      });
  }, []);

  const onSaveCallback = (organization: Organization) => {
    setItems([...items, organization]);
    setOrganizationid(organization.organizationid);
    setStatus(Status.list);
  };

  const onCancelCallback = () => {
    setStatus(Status.list);
  };

  const makeInvoice = () => {
    if (!organizationid) return alert("Укажите организацию на которую оформить счет.");
    setBillStatus(BillStatus.pending);

    InvoiceService.makeFromCart("", organizationid)
      .then((res) => {
        InvoiceService.createBill(res.data.invoiceid)
          .then(() => {
            setInvoiceId(res.data.invoiceid);
            setBillStatus(BillStatus.ready);
          })
          .catch(() => alert("Failed by create bill"));
      })
      .catch((e: any) => {
        if (e.response) {
          alert(e.response.data.message); // setError(e.response.data.message);
        } else if (e.request) {
          alert(e.request); // setError(e.request);
        } else {
          alert(e.message); // setError(e.message);
        }
      });
  };

  return (
    <>
      {status === Status.list && (
        <>
          <h2>Оформить на:</h2>
          <select className="w-100 rounded p-2" value={organizationid} onChange={(e) => setOrganizationid(e.target.value)}>
            <option disabled value="">
              --Укажите юр. лицо--
            </option>
            {items.map((item: Organization) => (
              <option value={item.organizationid} key={item.organizationid}>
                {item.name}
              </option>
            ))}
          </select>
          <Button variant="outline-secondary" className="w-100 my-3 border" onClick={() => setStatus(Status.add)}>
            + Добавить новую организацию
          </Button>
          <p className="mt-5">
            Подтверждая заказ, Вы соглашаетесь с условиями{" "}
            <Link to="/privacy">
              <u>лицензионного соглашения</u>
            </Link>
            .
          </p>
          {billStatus === BillStatus.notFormed && (
            <Button className="style-btn-action px-5 py-3 w-100" onClick={makeInvoice}>
              <img className="me-2" src={downloads} alt="download-price" />
              Сформировать счет
            </Button>
          )}
          {billStatus === BillStatus.pending && (
            <Button className="style-btn-action px-5 py-3 w-100" disabled>
              Пожалуйста подождите
            </Button>
          )}
          {billStatus === BillStatus.ready && (
            <Button href={`${config.API_URL}/invoice/bill/${invoiceId}`} target="_blank" className="style-btn-action px-5 py-3 w-100">
              {/* <a href={`${config.API_URL}/invoice/bill/${invoiceId}`} target="_blank" download rel="noreferrer"> */}
              Скачать счет
              {/* </a> */}
            </Button>
          )}
        </>
      )}
      {status === Status.add && (
        <OrganizationControl
          state={OrganizationControlState.add}
          organization={{} as Organization}
          saveCallback={onSaveCallback}
          cancelCallback={onCancelCallback}
        />
      )}
    </>
  );
};

export default OrganizationOrder;
