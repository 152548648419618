import $api from "../http";
import { AxiosResponse } from "axios";
import { IAuthResponse } from "../models/AuthResponse";
import { IUser } from "../models/User";

export default class AuthService {
  static async login(email: string, password: string): Promise<AxiosResponse<IAuthResponse>> {
    return $api.post<IAuthResponse>("auth/login", { email, password });
  }

  static async registration(
    email: string,
    password: string,
    surname: string,
    name: string,
    middlename: string,
    phone: string
  ): Promise<AxiosResponse<IAuthResponse>> {
    return $api.post<IAuthResponse>("auth/registration", { email, password, surname, name, middlename, phone });
  }

  static async logout(): Promise<void> {
    return $api.post("auth/logout");
  }

  static checkLogin(): Promise<AxiosResponse<IUser>> {
    return $api.get<IUser>("auth/checklogin");
  }
}
