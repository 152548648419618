import $api from "../http";
import { AxiosResponse } from "axios";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";

export type IInvoice = {
  invoiceid: string;
  invoicenumber: number;
  currencyid: string;
  currencyname: string;
  currencynamesys: string;
  currencynameshort: string;
  invoicestatusid: string;
  invoicestatusname: string;
  invoicestatusnamesys: string;
  organizationid: string;
  organizationname: string;
  comment: string;
  cnt: number;
  price: number;
  paymentid: string;
  recorddatecreate: Date;
};

export type IInvoiceList = {
  totalcount: number;
  items: IInvoice[];
};

export type IInvoiceItem = {
  invoiceitemid: string;
  invoiceid: string;
  nomenclatureid: string;
  name: string;
  namefull: string;
  cnt: number;
  price: number;
  measurename: string;
  images: any[];
};

export class InvoiceService {
  static async getAll(limit: number, currentPage: number): Promise<AxiosResponse<IInvoiceList>> {
    return $api.get<IInvoiceList>("/invoice", {
      params: pickBy(
        {
          page: currentPage,
          limit,
        },
        identity
      ),
    });
  }

  static async getAllItems(invoiceid: string): Promise<AxiosResponse<IInvoiceItem[]>> {
    return $api.get<IInvoiceItem[]>(`/invoice/${invoiceid}`);
  }

  static async makeFromCart(comment: string, organizationid: string = ""): Promise<AxiosResponse<IInvoice>> {
    const res = $api.post<IInvoice>(`/invoice/make`, { comment, organizationid });
    return res;
  }
  static async updateOrganization(invoiceid: string, organizationid: string): Promise<AxiosResponse<IInvoice>> {
    const res = $api.post<IInvoice>(`/invoice/updateorganization`, { invoiceid, organizationid });
    return res;
  }
  static async getConfirmationUrl(invoiceid: string): Promise<AxiosResponse<any>> {
    return $api.get<any>(`/invoice/confirmationurl/${invoiceid}`);
  }
  static async makeYookassa(invoiceid: string): Promise<AxiosResponse<any>> {
    return $api.post(`/invoice/makeyookassa`, { invoiceid });
  }

  // Bill
  static async downloadBill(invoicenumber: string): Promise<AxiosResponse> {
    return $api.get(`invoice/bill/${invoicenumber}`);
  }
  static async createBill(invoiceid: string) {
    $api.post(`invoice/bill`, { invoiceid });
  }
}
