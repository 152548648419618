export type CartItem = {
  // cartid: string;
  nomenclatureid: string;
  name: string;
  price: number;
  images: { imageid: string; nomenclatureid: string; nomenclatureimageid: string; name: string; namesys: string };
  cnt: number;
};

export interface CartSliceState {
  totalPrice: number;
  items: CartItem[];
  status: CartStatus;
}

export enum CartStatus {
  LOADING = "loading",
  SUCCESS = "completed",
  ERROR = "error",
}
